import React from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Img from 'gatsby-image'



const Blog = ({data}) => {
    const {edges} = data.allMarkdownRemark
    return (
      <Layout>
        <SEO title="Blog" />
        <div>
          {edges.map(edge => {
            const { frontmatter } = edge.node
            const featuredImage = frontmatter.featuredImage.childImageSharp.fluid
            return (
                <div className="bg-white max-w-5xl p-4 my-4 shadow-2xl border-l-8 border-green-500" key={frontmatter.path}>
                <h2 className="font-bold text-4xl pb-2"><Link to={frontmatter.path}>{frontmatter.title}</Link></h2>
                <Img className="z-10" fluid={featuredImage} />
                <p className="py-4">{frontmatter.excerpt}</p>
                    <Link to={frontmatter.path}><span className="font-semibold text-red-600">Read More...</span></Link>
              </div>
            )
          })}
        </div>
      </Layout>
    )
}

export const query = graphql`
    query HomepageQuery {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        title
                        path
                        date
                        excerpt
                        featuredImage { 
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                 }
                            }
                        }
                    }
                }
            }
        }
    }`

export default Blog
